<template>
    <div>
        <OrgAndNumber module='storage'></OrgAndNumber>
    </div>
</template>
<script>
import OrgAndNumber from '../user/components/orgAndNumber.vue'
export default {
    components:{
        OrgAndNumber
    }
}
</script>